<template>
  <container-questions screen />
</template>

<script>
import ContainerQuestions from "@/containers/common/ContainerQuestions";

export default {
  name: "ViewScreenQuestions",
  components: { ContainerQuestions },
};
</script>